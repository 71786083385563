export enum gitProviderName{ // sync with back
  CODECOMMIT = "code_commit",
  GITHUB = "github",
  GITLAB = "gitlab"
}

export const gitProviderAffichage = {
   [gitProviderName.CODECOMMIT] : "Code commit",
   [gitProviderName.GITHUB] : "Github",
   [gitProviderName.GITLAB] : "Gitlab",
 }
 export const gitProviderListAffichage = Object.keys(gitProviderAffichage)

export interface GitlabConfig{
  namespace_id? : string,
  token : string,
  host?: string
}

export interface GithubConfig{
  accessToken : string,
  orgName : string
}


export interface GitProvider{
    provider? : string,
    githubConfig? : GithubConfig,
    gitlabConfig? : GitlabConfig
}

